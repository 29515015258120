<template>
  <div class="content" :class="isNoData ? 'content1' : ''">
    <div class="titleWrap">
      <!--            <van-icon name="arrow-left" style="line-height: 42px"/>-->
      <div class="titleText">
        <p>{{ startAddress }}</p>
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png"
          alt=""
        >
        <p>{{ endAddress }}</p>
      </div>
    </div>
    <!--        日历-->
    <div class="CalendarWrap">
      <div class="dateList">
        <div v-for="(item,index) in dateTenList" :key="index" class="dateTenList">
          <div style="border-right: 1px solid #e8e8e8;padding: 0 10px;" @click="selDate(item,index)">
            <div class="dateTenStyle" :class="selDateIndex === index ? 'dateTenStyle1' : ''">
              <p>{{ item.time }}</p>
              <p>{{ item.week }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="CalendarIconWrap" @click="openCalendar">
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhui5RGLF9uWEB1609075991.png"
          alt=""
        >
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhui47hgGUtbb01609076020.png"
          alt=""
        >
      </div>
    </div>
    <!--        航班列表-->
    <div class="FlightListWrap">
      <div v-if="isNoData" class="noData">
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuicVzblNVGaq1609079862.png"
          alt=""
        >
        <p>很遗憾，当天没有航班，请选择其他日期</p>
      </div>
      <div v-else class="dataListWrap">
        <div v-for="(item,index) in dataList" :key="index" class="dataContent" @click="goDetail(item)">
          <div class="dataList">
            <div class="FlightContent">
              <img :src="item.flightCompanyLogo" alt="">
              <div class="FlightTimeWrap">
                <div class="FlightTimeStart">
                  <p>{{ item.depTime }}</p>
                  <p>{{ item.depAirport }}</p>
                </div>
                <img
                  src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png"
                  alt=""
                >
                <div class="FlightTimeConclusion">
                  <p>{{ item.arrTime }}</p>
                  <p>{{ item.arrAirport }}{{ item.arrivingterminal }}</p>
                </div>
              </div>
              <div class="FlightPriceWrap">
                <p>¥<span>{{ item.price }}</span></p>
                <p>{{ item.shippingSpace }}{{ item.discount }}折</p>
              </div>
            </div>
            <div class="FlightInfo">
              <p>{{ item.flightCompanyName }}{{ item.flightCompanyCode }}</p>
            </div>
          </div>
          <div class="van-hairline--bottom" />
        </div>
        <!--        没有更多了-->
        <div class="noMore">
          没有更多了～
        </div>
      </div>
    </div>
    <!--        底部选择-->
    <div class="botBtnWrap">
      <div class="van-hairline--top" />
      <div class="BtnWrapList">
        <div v-for="(item,index) in btnList" :key="index" class="btnStyle" @click="clickSearch(item)">
          <img :src="btnIndex === item.code ? item.iconOne : item.icon" alt="">
          <div :class="btnIndex === item.code && item.code != 0 ? 'btnStyleP' : ''">
            <p v-if="item.number === 0">{{ item.titleOne }}</p>
            <p v-if="item.number === 1">{{ item.titleTwo }}</p>
            <p v-if="item.number === 2">{{ item.titleThree }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--        日历-->
    <van-calendar v-model="OneWayShow" title="选择出发日期" @confirm="onConfirm" />
    <!--        筛选弹窗-->
    <van-action-sheet v-model="FlightScreening">
      <div class="FlightScreeningWrap">
        <div class="ScreeningTitle">
          <p @click="ClosedScreening">取消</p>
          <div class="clearScreening" @click="ClearScreening">清空筛选</div>
          <p @click="ConfirmationScreening">确定</p>
        </div>
        <div class="van-hairline--bottom" />
        <div class="ScreeningHidden" @click="ScreeningHiddens">
          <p>隐藏共享航班</p>
          <img
            :src="hideShare ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png'"
            alt=""
          >
        </div>
        <div class="van-hairline--bottom" />
        <div class="ScreeningSel">
          <van-tree-select height="55vw" :items="items" :main-active-index.sync="active">
            <template #content>
              <div v-if="active === 0">
                <div
                  v-for="(item,index) in TimeDepartureList"
                  :key="index"
                  class="ScreeningConditionsWrap"
                  @click="selTimeDeparture(item)"
                >
                  <p>{{ item.time }}</p>
                  <img
                    :src="item.isSel ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png'"
                    alt=""
                  >
                </div>
              </div>
              <div v-if="active === 1">
                <div
                  v-for="(item,index) in AviationDivisionList"
                  :key="index"
                  class="ScreeningConditionsWrap"
                  @click="selAviation(item)"
                >
                  <p>{{ item.name }}</p>
                  <img
                    :src="item.isSel ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png'"
                    alt=""
                  >
                </div>
              </div>
            </template>
          </van-tree-select>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet,
  Calendar,
  Icon,
  Loading,
  Sidebar,
  SidebarItem,
  Toast,
  TreeSelect
} from 'vant'

Vue.use(Icon)
  .use(Calendar)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)

import {
  getTickedList,
  getDateTenList
} from '@/services/comeOn.js'

export default {
  name: 'TickedList',
  data() {
    return {
      isType: '', // 是否单程
      startAddress: '', // 出发地
      endAddress: '', // 目的地
      startCode: '', // 出发地3字码
      endCode: '', // 目的地3字码
      isChildren: '', // 是否儿童票 0 1
      sortType: 2, // 默认1   1时间2价格3时间倒叙4价格倒叙 默认查询价格倒叙
      startTime: '', // 出发时间
      endTime: '', // 返程时间
      CurrentDate: '', // 当前日期
      dateTenList: [], // 当前时间10天的日期
      selDateIndex: 0, // 选中的日期
      OneWayShow: false, // 日历组件
      isNoData: true, // 无数据
      btnList: [
        {
          code: 0,
          titleOne: '筛选',
          titleTwo: '筛选',
          titleThree: '筛选',
          icon: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuiQ6PoSJk8a41609080674.png',
          iconOne: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuiQ6PoSJk8a41609080674.png',
          searchSwitch: true,
          number: 0
        }, {
          code: 1,
          titleOne: '时间',
          titleTwo: '时间晚-早',
          titleThree: '时间早-晚',
          icon: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuiRLlM5nnppf1609080696.png',
          iconOne: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuiEKCPMsJVTD1609080788.png',
          searchSwitch: true,
          number: 0
        }, {
          code: 2,
          titleOne: '价格',
          titleTwo: '价格低-高',
          titleThree: '价格高-低',
          icon: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuikeQY4Zhr7V1609080717.png',
          iconOne: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuiQllQATLueM1609080754.png',
          searchSwitch: true,
          number: 1
        }
      ], // 底部按钮
      btnIndex: 2,
      searchIndex: 2,
      dataList: [], // 航班列表
      hideShare: false, // 共享航班
      FlightScreening: false, // 筛选
      hideShareImg1: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png',
      hideShareImg2: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png',
      active: 0,
      items: [{ text: '起飞时间' }, { text: '航空公司' }],
      TimeDepartureList: [], // 起飞时间
      AviationDivisionList: []// 航空公司
    }
  },
  created() {

  },
  mounted() {
    this.isType = this.$route.query.isType ? 'OW' : 'RT'// OW 单程  RT 往返
    this.startAddress = this.$route.query ? this.$route.query.startAddress : this.$route.query.startAddress
    this.endAddress = this.$route.query ? this.$route.query.endAddress : this.$route.query.endAddress
    this.startCode = this.$route.query ? this.$route.query.startCode : this.$route.query.startCode
    this.endCode = this.$route.query ? this.$route.query.endCode : this.$route.query.endCode
    this.isChildren = this.$route.query ? this.$route.query.isChildren : this.$route.query.isChildren
    this.isChildren = this.isChildren ? 0 : 1
    // this.CurrentDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;//当前时间
    this.startTime = this.$route.query ? this.$route.query.startTime : new Date()
    this.CurrentDate = this.startTime
    this.getDateTenLists(this.CurrentDate)// get当前日期10天时间
    this.getTickedLists()// get航班列表 默认查询的航班是价格从低到高
  },
  methods: {
    // get航班列表
    getTickedLists() {
      Toast.loading('航班加载中')
      getTickedList({
        departureCity: this.startCode, // 出发城市3字码
        reachingCity: this.endCode, // 到达城市3字码
        date: this.CurrentDate, // 时间
        sortType: this.sortType, // 默认1   1时间2价格3时间倒叙4价格倒叙
        // dctype:'',//暂时弃用
        // rctype:'',//暂时弃用
        childNum: this.isChildren, // 是否儿童票
        tripType: this.isType, // OW 单程  RT 往返
        // returnDate:'',//返程日期
        checkExt: {
          hideShare: this.hideShare, // true/false共享航班
          flyTimeInterval: this.flyTimeInterval1, // 时间段12：00-2：00
          aircraftPosition: '', // 舱位：经济舱
          airlineCompanyCode: this.airlineCompanyCode1// 航司号码
        }// 筛选条件
      }).then(res => {
        if (Number(res.code) === 200) {
          if (res.data.depart.list.length === 0) {
            this.isNoData = true
          } else {
            this.isNoData = false
            this.dataList = res.data.depart.list// 航班列表
            const flyTimeIntervalList = res.data.depart.checkList.flyTimeIntervalList
            const airlineCompanyList = res.data.depart.checkList.airlineCompanyList
            // 时间列表
            for (let i = 0; i < flyTimeIntervalList.length; i++) {
              flyTimeIntervalList[i].isSel = false
            }
            this.TimeDepartureList = flyTimeIntervalList
            // 航司列表
            for (let i = 0; i < airlineCompanyList.length; i++) {
              airlineCompanyList[i].isSel = false
            }
            this.AviationDivisionList = airlineCompanyList
            Toast.clear()
          }
        }
      })
    },
    // get当前时间10天的时间
    getDateTenLists(CurrentDate) {
      getDateTenList({
        date: CurrentDate// 2020-07-28
      }).then(res => {
        this.dateTenList = res
        for (let i = 0; i < res.length; i++) {
          res[i].time = res[i].date.substring(5)
          this.dateTenList.concat(res[i])
        }
      })
    },
    // 选择时间
    selDate(item, index) {
      this.selDateIndex = index
      this.CurrentDate = item.date
      this.getTickedLists()// 搜索航班
    },
    // 打开日历并选择
    openCalendar() {
      this.OneWayShow = true
    },
    // 转化日历组件返回的日期格式
    formatDate(date) {
      let month, day
      if (date.getMonth() + 1 < 10) {
        month = '0' + (date.getMonth() + 1)
      } else {
        month = date.getMonth() + 1
      }

      if (date.getDate() < 10) {
        day = '0' + date.getDate()
      } else {
        day = date.getDate()
      }
      return `${date.getFullYear()}-${month}-${day}`
      // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    // 日历日期选择
    onConfirm(date) {
      this.CurrentDate = this.formatDate(date)// 选择的时间
      // 选择时间的10天
      this.getDateTenLists(this.CurrentDate)
      // 选择时间的机票列表
      // this.CurrentDate = (this.dateTenList)[0].date;
      this.getTickedLists()
      this.selDateIndex = 0
      this.OneWayShow = false
    },
    // 点击搜索
    clickSearch(item) {
      item.searchSwitch = !item.searchSwitch
      if (item.code === 0) { // 筛选
        this.FlightScreening = true
        return false
      } else {
        this.btnIndex = item.code
        if (item.number === 2) {
          item.number = 1
        } else {
          item.number++
        }
        for (let i = 0; i < this.btnList.length; i++) {
          if (this.btnList[i].code !== item.code) {
            this.btnList[i].number = 0
          }
        }
        if (item.code === 1) { // 时间
          if (item.number === 1) { // 晚-早
            this.sortType = 3
          } else if (item.number === 2) { // 早-晚
            this.sortType = 1
          }
        } else if (item.code === 2) { // 价格
          if (item.number === 1) { // 低-高
            this.sortType = 2
          } else if (item.number === 2) { // 高-低
            this.sortType = 4
          }
        }
        // 搜索航班
        this.getTickedLists()
      }
    },
    // 关闭筛选
    ClosedScreening() {
      this.FlightScreening = false
    },
    // 清空筛选
    ClearScreening() {
      this.hideShare = false
      for (let i = 0; i < this.AviationDivisionList.length; i++) {
        this.AviationDivisionList[i].isSel = false
      }
      for (let i = 0; i < this.TimeDepartureList.length; i++) {
        this.TimeDepartureList[i].isSel = false
      }
    },
    // 确认筛选
    ConfirmationScreening() {
      this.FlightScreening = false
      this.flyTimeInterval1 = this.flyTimeInterval// 筛选时间段
      this.airlineCompanyCode1 = this.airlineCompanyCode// 筛选航司号码
      this.getTickedLists()
    },
    // 隐藏共享航班
    ScreeningHiddens() {
      this.hideShare = !this.hideShare
    },
    // 切换筛选侧边栏导航
    Screening(index) {
      if (index === 0) { // 起飞时间

      } else if (index === 1) { // 航空公司

      }
    },
    // 选择航空
    selAviation(item) {
      for (let i = 0; i < this.AviationDivisionList.length; i++) {
        this.AviationDivisionList[i].isSel = false
      }
      item.isSel = true
      this.airlineCompanyCode = item.code// 航司号码
    },
    // 选择时间
    selTimeDeparture(item) {
      for (let i = 0; i < this.TimeDepartureList.length; i++) {
        this.TimeDepartureList[i].isSel = false
      }
      item.isSel = true
      this.flyTimeInterval = item.time// 航司号码
    },
    // go详情
    goDetail(item) {
      this.$router.push({
        path: '/tickedDetail',
        query: {
          startAddress: this.startAddress,
          endAddress: this.endAddress,
          flightInfoId: item.flightinfoid, // foid
          childNum: this.isChildren, // 儿童票 1/0
          tripType: this.isType, // 行程类型 OW单程RT往返
          dataExt: {
            rtFlightInfoId: ''// 返程foid
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content1 {
  background-color: #fff;
}

.content {
  position: absolute;

  .titleWrap {
    width: 100%;
    height: 42px;
    background-color: #fff;
    text-align: center;
    line-height: 42px;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1;

    .titleText {
      display: flex;
      line-height: 42px;

      p {
        font-size: 17px;
        color: #333333;
        font-weight: 600;
      }

      img {
        width: 16px;
        height: 5px;
        margin: 17px 8px;
      }
    }
  }

  /*    日历*/

  .CalendarWrap {
    position: fixed;
    z-index: 1;
    top: 42px;
    left: 0;
    width: 100%;
    height: 67px;
    background-color: #fff;
    display: flex;
    /*10天数据*/

    .dateList {
      display: flex;
      overflow: scroll;
      white-space: nowrap;
      width: 80%;

      .dateTenList {
        width: 69%;
        padding: 10px 0;
        font-size: 12px;
        color: #333333;

        .dateTenStyle {
          line-height: 20px;
          padding: 4px 10px;
          border-radius: 3px;
        }

        .dateTenStyle1 {
          background-color: #E33F44;
          color: #ffffff;
        }
      }
    }

    .CalendarIconWrap {
      width: 20%;
      text-align: center;
      line-height: 67px;
      border-left: 1px solid #f8f8f8;
      position: relative;
      /*box-shadow:1px 0 10px 0px #aaa;*/
      //box-shadow: -5px 0 3px -3px #aaa;
      img:nth-child(1) {
        position: absolute;
        width: 18px;
        height: 18px;
        left: 50%;
        margin-left: -9px;
        top: 50%;
        margin-top: -9px;
      }

      img:nth-child(2) {
        position: absolute;
        width: 8px;
        height: 5px;
        left: 50%;
        margin-left: -4px;
        top: 80%;
        margin-top: -8px;
      }
    }
  }

  /*    航班列表*/

  .FlightListWrap {
    position: relative;
    padding-bottom: 100px;
    background-color: #f8f8f8;

    .noData {
      position: absolute;
      text-align: center;
      top: 102px;
      left: 50%;
      margin-left: -110px;

      img {
        width: 135px;
        height: 121px;
      }

      p {
        font-size: 12px;
        color: #999999;
      }
    }

    .dataListWrap {
      margin-top: 119px;
      width: 100%;

      .dataList {
        background-color: #fff;
        padding: 23px 17px 16px 17px;

        .FlightContent {
          display: flex;

          img {
            width: 20px;
            height: 23px;
            margin-right: 7px;
          }

          .FlightTimeWrap {
            display: flex;
            width: 60%;

            .FlightTimeStart, .FlightTimeConclusion {
              color: #333333;

              p:nth-child(1) {
                font-size: 20px;
                font-weight: 600;
              }

              p:nth-child(2) {
                font-size: 12px;
                margin-top: 7px;
              }
            }

            img {
              width: 50px;
              height: 5px;
              margin: 9px;
            }

            .FlightTimeConclusion {

            }
          }

          .FlightPriceWrap {
            margin-left: auto;

            p:nth-child(1) {
              font-size: 12px;
              color: #E33F44;

              span {
                display: inline-block;
                font-size: 20px;
                margin-left: 2px;
              }
            }

            p:nth-child(2) {
              font-size: 12px;
              color: #999999;
              margin-top: 7px;
            }
          }
        }

        .FlightInfo {
          display: flex;
          margin-top: 12px;

          p {
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
  }

  .noMore {
    margin-top: 17px;
    font-size: 12px;
    color: #999999;
    text-align: center;
  }

  /*    底部选择*/

  .botBtnWrap {
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 57px;

    .BtnWrapList {
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: flex;

      .btnStyle {
        width: 33%;
        text-align: center;
        margin-top: 11px;

        img {
          width: 18px;
          height: 18px;
        }

        p {
          font-size: 12px;
          color: #999999;
        }

        .btnStyleP {
          p {
            color: #E33F44;
          }
        }
      }
    }
  }

  /*    筛选弹窗*/

  .FlightScreeningWrap {
    width: 100%;

    .ScreeningTitle {
      display: flex;
      /*height: 38px;*/
      padding: 16px;
      position: relative;
      font-size: 14px;
      color: #9B9B9B;
      line-height: 25px;

      .clearScreening {
        position: absolute;
        left: 50%;
        margin-left: -36px;
        width: 73px;
        height: 25px;
        text-align: center;
        border: 1px solid #D0D0D0;
        border-radius: 5px;
      }

      p:nth-child(1) {
        line-height: 30px;
      }

      p:nth-child(3) {
        color: #E33F44;
        margin-left: auto;
        line-height: 30px;
      }
    }

    .ScreeningHidden {
      display: flex;
      padding: 16px;

      p {
        font-size: 14px;
        color: #000000;
      }

      img {
        width: 17px;
        height: 17px;
        margin-left: 7px;
      }
    }

    .ScreeningSel {
      padding-top: 1px;

      .ScreeningConditionsWrap {
        display: flex;
        padding: 21px;

        p {
          font-size: 14px;
          color: #4e4e4e;
        }

        img {
          margin-left: auto;
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}
</style>
